import React from 'react'
import './Header.css'

const Header = () => {
  return (
    <header className="Header">
      <h1>Star Wars Search</h1>
    </header>
  )
}

export default Header
